import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider, StyleReset } from 'atomize';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

const debug =
  process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

const engine = new Styletron();

const theme = {
    colors: {
      primary: 'tomato',
      accent: 'yellow',
    },
    transition: {
        custom: "transform 0.8s linear"
    },
    grid: {
        containerWidth: {
            xs: "540px",
            sm: "720px",
            md: "1100px",
            lg: "1356px",
            xl: "1656px"
        },
        gutterWidth: "12px",
    },
    textSize: {
        size: {
          h2: "32px",
          h3: "22px",
          h4: "18px"
        },
        height: {
          h2: "42px",
          h3: "31px",
          h4: "28px"
        }
      }
  };

ReactDOM.render(
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
        <ThemeProvider theme={theme}>
            <StyleReset />
            <App />
        </ThemeProvider>
    </StyletronProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

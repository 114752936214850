import React from 'react';
import { Text, Image, Anchor, Div, Col, Row, Icon } from "atomize";
import logo from './logo.png';
import hgv_logo from './assets/hgv-logo-v4.png';
import './App.css';
import IMG_Classic from './assets/classicimg.jpeg';
import IMG_Cables from './assets/cablesimg2.jpg';
import IMG_Techie from './assets/techieimg.jpg';
import IMG_VanMount from './assets/vanmountable2.jpg';
import IMG_KSM from './assets/ksm.jpg';
import IMG_CMB from './assets/CMB.jpg';

function App() {
    return (
        <div style={{padding:'0 50px'}}>
            <Row style={{marginBottom:'100px'}}>
                <Col size={{xs: 12, sm:12, md:12, lg:6}}>
                    <Div style={{padding:'0 30px 30px 0'}}>
                        <Text tag="h1" style={{display:'none'}}>MK Trailer Checker</Text>
                        <Image src={logo} className="App-logo" alt="logo"/>
                        <Text tag="h4" textSize="h4">Checking 24volt trailer lights? No tractor unit?</Text>
                        <Text tag="h4" textSize="h4">No problem.</Text>
                        <Text>
                            An economically priced and invaluable tool for any commercial workshop or service/recovery vehicle.
                            Simply connect tool to the trailer headboard, connect 24V supply, either booster pack or a pair of
                            12v batteries (car or commercial) and the tool is ready for use.
                            This tool allows you to check commercial trailer lights without the need to have a tractor unit
                            connected, as well as checking the ABS light (if fitted) it will prove their is power to the
                            trailers ECU.
                            Bespoke versions can be made to customer’s requirements.
                        </Text>
                    </Div>
                </Col>
                <Col size={{xs: 12, sm:12, md:12, lg:6}} style={{alignSelf:'center'}}>
                    <Div >
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/UGPk_hyTa5w?si=P0CE-JOvcwFz4HEu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="youtube-video"></iframe>
                    </Div>
                </Col>
            </Row>

            <Row style={{marginBottom:'30px', }}>
                <Text tag="h2" textSize="h2">Products</Text>
            </Row>

            <Row style={{marginBottom:'30px'}}>
                <Col size={{xs: 0, sm:0, md:1, lg:1}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_Classic} alt={""}/>
                    <Text tag="h3" textSize="h2">The Classic</Text>
                    <Text>Our first model, and the most popular amongst our customers. Uses standard 24N & 24S suzies via sockets.</Text>
                </Col>
                <Col size={{xs: 0, sm:0, md:2, lg:2}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_Cables} alt={""}/>
                    <Text tag="h3" textSize="h2">Cables all attached</Text>
                    <Text>An Extension of our Classic model, with the all the required cables build in</Text>
                </Col>
            </Row>

            <Row style={{marginBottom:'100px'}}>
                <Col size={{xs: 0, sm:0, md:1, lg:1}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_Techie} alt={""}/>
                    <Text tag="h3" textSize="h2">The Techies Favourite</Text>
                    <Text>Our advanced model, using bluetooth and mobile apps to control, test and log your trailers
                        rig/history.</Text>
                </Col>
                <Col size={{xs: 0, sm:0, md:2, lg:2}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_VanMount} alt={""}/>
                    <Text tag="h3" textSize="h2">Van Mountable</Text>
                    <Text>An extension of our Classic model, mountable on vehicles. Attach your own Suzie Coils. 5 metre extension cables available (not supplied).</Text>
                </Col>
            </Row>


            <Row style={{marginBottom:'30px'}}>
                <Text tag="h2" textSize="h2">Testimonials</Text>
            </Row>

            <Row style={{marginBottom:'100px'}}>
                <Col size={{xs: 0, sm:0, md:1, lg:1}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_KSM} alt={""}/>
                    <Text tag="h3" textSize="h2">KSM Ltd</Text>
                    <Text>All my vehicles are equipped with these testers, an invaluable tool. - <span style={{fontStyle:'italic'}}>Steve Hollis, KSM Ltd</span></Text>
                </Col>
                <Col size={{xs: 0, sm:0, md:2, lg:2}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image className={'productImg'} src={IMG_CMB} alt={""}/>
                    <Text tag="h3" textSize="h2">CMB Truck Assistance</Text>
                    <Text>Does exactly what you would expect it to do, Saves me so much time. <span style={{fontStyle:'italic'}}>Craig Brooksbank  - CMB Truck Assistance</span></Text>
                </Col>
            </Row>

            <Row style={{marginBottom:'30px'}}>
                <Text tag="h2" textSize="h2">Questions? Contact us!</Text>
            </Row>

            <Row style={{marginBottom:'50px'}}>
                <Col size={{xs: 0, sm:0, md:1, lg:1}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}} style={{marginBottom:'30px'}}>
                    <Image src={hgv_logo} className="App-logo" alt="hgv direct logo"/>
                </Col>
                <Col size={{xs: 0, sm:0, md:2, lg:2}}/>
                <Col size={{xs: 12, sm:12, md:4, lg:4}}>
                    <Text>Like what you see? Great! If not, we're sure we can build something to your specification, reach out!</Text>
                    {/* <Text><Icon name="LocationSolid" size="20px" /> The Elms, Moor Farm Road East, Ashbourne, Derbyshire, DE6 1HD</Text> */}
                    <Text><Icon name="Mail" size="20px" /> <Anchor href='mailto:matthewkempa@hotmail.co.uk'>martinkempa@hgvdirect.co.uk</Anchor> / 01606 210100</Text>
                </Col>
            </Row>
        </div>
    );
}

export default App;
